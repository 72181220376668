<template>
    <div>
        <!-- top-bar -->
         <div ref="navbar" class="navbar-custom">
                 <ul class="list-unstyled topnav-menu topnav-menu-left">
                 <li>
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item"><router-link to="/"><img src="@/assets/images/Common/icon/back_arrow.png" alt="" class="back_arrow">Home</router-link></li>
                      <li class="breadcrumb-item"><router-link to="/water-pressure">{{$t('pressure.title')}}</router-link></li>
                      <li class="breadcrumb-item active">{{$t('pressure.specification')}}</li>
                    </ol>
                </li>
    

                 </ul>
         </div>
     <!-- tom-bar-end -->
<!--      <div class="content-area">-->
        <search-left-type3 :cnt="cnt" :box="box" :activeCheckIndex="activeCheckIndex" @updateCheckIndex="updateCheckIndex" @go_search="go_search"
        :visible_sort_box_press="false" />
    
        <div class="right_area chart_box right_area_01 tab-chart-box" style="width: 77%; margin-top: 0;">
          <!--div class="tab_list_02 is--square" ref="tabList">
            <div :class="[`tab_btn tab_btn0${index+1}`, { 'on' : activeIndex === index }]" v-for="(item, index) in tabs" :key="index" @click="tabItem(index)">{{ item.name }}</div>
          </div-->
          <div class="tab_box_area" ref="tabBox">
        
            <div id="tab_box01" :class="['tab_box',{ 'on' : activeIndex === 0 }, ' press_logger']" >
              <div class="info_box">
                <ul class="list">
                  <li> {{$t('pressure_monitoring.name')}} : {{tag_name}}  </li>
                  <li>  {{file_info}}  </li>
                </ul>
          
               
                
              </div>
              
              <div class="img_area">
                <!-- <div  style=" background-color:white;"> -->
                <div  style=" ">
                    <!--div style="position:absolute; top:6%; right:365px;  z-index: 102;">
                    <button class="btn mr-2 map-button icon_btn btn-secondary btn-sm1" @click="change_date_flow(-1)"><i class="mdi mdi-menu-left mdi-24px"></i></button>
                    <button class="btn mr-2 map-button icon_btn btn-secondary btn-sm1" @click="change_date_flow(1)"><i class="mdi mdi-menu-right mdi-24px"></i></button>
                    </div>
                  <div ref="button_group_realtime">
                    <b-form inline class="btn_list_wrap" style=" top: 6%; right: 130px;">
                      <b-input size="sm" v-if="isShowDate" v-model="flow_date" type="date" @change="change_flow_date($event)" class="input_date m-0 btn-gray-date "/>
                      <b-button-group class="mr-2 btn_list">
                        <div class="lookup_btn search" @click="showDate">Search Date</div>                 
                        <b-radio class="min_btn" :button="true" size="sm" v-model="selected_ts_flow" value='30' @change="change_selected_ts_flow($event)">
                          <span>30D</span>
                        </b-radio>
                        <b-radio class="min_btn" :button="true" size="sm" v-model="selected_ts_flow" value='7' @change="change_selected_ts_flow($event)">
                          <span>7D</span>
                        </b-radio>
                        <b-radio :button="true" size="sm" v-model="selected_ts_flow" value='3' @change="change_selected_ts_flow($event)">
                          <span>3D</span>
                        </b-radio>
                        <b-radio :button="true" size="sm" v-model="selected_ts_flow" value='1' @change="change_selected_ts_flow($event)">
                          <span>1D</span>
                        </b-radio>
                      </b-button-group>

                    </b-form>
                    
                  </div-->
                   <div style="position:absolute; top:6%; right:20px;">
                     <div class="status leak ">
                      <input type="radio" id="c_radio_01" name="c_radio01"  v-model="cvchart" value="kgf">
                      <label for="c_radio_01">kgf</label>
                      <input type="radio" id="c_radio_02" name="c_radio02"  checked v-model="cvchart" value="bar">
                      <label for="c_radio_02">bar</label>
                      <input type="radio" id="c_radio_03" name="c_radio03"   v-model="cvchart" value="psi" >
                      <label for="c_radio_03">psi</label>
                       <span style="padding: 0 20px;"> <button class="btn btn-primary btn-g" style="width:120px;" @click="chart_exceldownload()" >{{$t('pressure_monitoring.excel_down')}}</button></span>
                    </div>
                   
                    </div>
                    <!--div class="status" style="top:6.5%;    right: 750px;" >                                            
                       
                          <span style="float: right;">
                            <b-input v-model="m_min" style="width:60px;height:20px;margin:0 0 0 10px;padding:10px 0;text-align:center;" 
                            type="number" step="0.25" class="input_spin" />
                        
                          </span> 
                    </div-->  
                  
                  <div><!--
                      <div>
                        <pulse-loader class="loading" v-if="!loaded_ts_flow" loading="loading" color="#FF5733" />
                    </div>
                      <PressMinChart :url="real_url" ref="realchart" id="realchart" :height="mnfchart_height" style="position:relative;width:100%;height:100%;top:25px;"   />  -->
                       <p>
                         <pressChartData1 class="presschart_m2_c1" :url="real_url"  ref="realchart" id="realchart" :height="mnfchart_height" style="position:relative;width:100%;height:100%;    margin-top: 30px;"   />
                       </p> 
                  </div> 
                </div>
              </div>
            </div>
           
            <div id="tab_box03" :class="['tab_box ',{ 'on' : activeIndex === 1 }]">
           
             
           
             <div class="table_box_wrap scroll_box" style="margin-top:10px;">
              <!-- 2023 -->
              <!-- <table class="with--bg">
                <colgroup>
                  <col style="width:110px">
                  <col style="width:55px">
                </colgroup>
                <thead>
                  <tr>
                    <th>yyyy-MM</th>
                    <th>1</th>
                    <th>2</th>
                    <th>3</th>
                    <th>4</th>
                    <th>5</th>
                    <th>6</th>
                    <th>7</th>
                    <th>8</th>
                    <th>9</th>
                    <th>10</th>
                    <th>11</th>
                    <th>12</th>
                    <th>13</th>
                    <th>14</th>
                    <th>15</th>
                    <th>16</th>
                    <th>17</th>
                    <th>18</th>
                    <th>19</th>
                    <th>20</th>
                    <th>21</th>
                    <th>22</th>
                    <th>23</th>
                    <th>24</th>
                    <th>25</th>
                    <th>26</th>
                    <th>27</th>
                    <th>28</th>
                    <th>29</th>
                    <th>30</th>
                    <th>31</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, i) in items" :key="i">
                  
                    <td >{{item.ym}}</td>
                    <td v-for="(tb_v, index) in item.tb_v" :key="index">{{tb_v.max}}<br>{{tb_v.avg}}<br>{{tb_v.min}}<br>{{tb_v.mnp}}</td>
                  
                  </tr>
                
                </tbody>
              </table> -->
              
 
            </div>
            </div>
          </div>
        </div>

      </div>

</template>

<script>
import SearchLeftType3 from '@/components/left/SearchLeftType3_pressL';
import * as d3 from 'd3'
//import BlockMonitoringBarMnfChart from '@/components/BlockMonitoringBarMnfChart' ;
//import BlockMonRealTime from '@/components/BlockMonRealTime' ;
import d3tip from "d3-tip";
d3.tip = d3tip;
//import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
//import PressMonitoringDayChart from '@/components/PressMonitoringDayChart' ;
//import PressMinChart from '@/components/PressMinChart' ;
import pressChartData1 from '@/components/chart/pressChartHammer' ;

export default {
    name: 'PressMoniHammerDetailTab',
    props: [      
    ],
    watch: {
      min() {
        if(this.vchart==='0') {
          return;
        }
         this.chang_bar_url();
      },
      cvchart(){
         this.get_time_series_flow();
      }, 
      vchart1() {
 
        this.$refs.realchart.fixed = this.vchart1 ;
        this.$refs.realchart.min = this.m_min ;
        var c_url = this.real_url.split('#') ;
       // this.real_url = this.URL+'api/API21/m2_press/minute/'+encodeURI(this.tag)+'?to='+this.flow_date+'&limit='+this.selected_ts_flow+"#1";
 
        this.real_url = c_url[0]+"&fixed="+this.vchart1+"&"+this.m_min+'#1' ;
      } ,      
      m_min() {
        if(this.vchart1 === 1 ){
          this.$refs.realchart.fixed = 1 ;
          this.$refs.realchart.min = this.m_min ;
        //  var c_url = this.real_url.split('#') ;
        var c_url = this.real_url.split('&fixed=') ;
         // this.real_url = this.URL+'api/API21/m2_press/minute/'+encodeURI(this.tag)+'?to='+this.flow_date+'&limit='+this.selected_ts_flow+"#1";
 
          this.real_url = c_url[0]+"&fixed=1&"+this.m_min+'#1' ;
        }else{

           if(this.init_min !== 0){
             this.vchart1 =  1 ;
          }else{
            ++this.init_min;
          }
        }
    
      }        
    },
    data() {      
        return {
            cnt:0,
            bar_url : '',
            bar_url1:'',
            real_url : '',
            cvchart : "bar",
            min : 3,
            vchart1 : 0,
            m_min : 2,
            init_min : 1,
            tabs: [
              { 'name': this.$t('pressure_monitoring.tab_title2') },
              { 'name': this.$t('pressure_monitoring.tab_title1') },
              { 'name': this.$t('pressure_monitoring.tab_title3') }
            ],
            activeIndex: 0,
            activeCheckIndex: null,
            box: [
            
            ],
            isShowDate: true,
            isShowDate1: true,
            contents_height: 600,
            mnfchart_height: 550,
            realtimechart_height: 550,
            isInit : true,
            isInit1 : true,

            selected_ts_flow: '30',
            flow_date: '2020-11-01',
            loaded_ts_flow: false,

            selected_ts_mnf: '1',
            mnf_date: '2020-11-01',
            mnp_boundary: null,
            columns: [
                {label: 'Date', field: 'date_str'},
                {label: 'Supply(㎥/day)', field: 'flow'},
                {label: 'MNF', field: 'mnf'},
                {label: '%', field: 'rate'},
            ],
            table_mnf: [], //테이블 데이터 존재
            items: [
                   //   { id: 1, name: 'Item 1', sub: [{ title: 'SubItem Item 1-1'}, { title: 'SubItem Item 1-2'}]},
                   //   { id: 2, name: 'Item 2', sub: [{ title: 'SubItem Item 2-1'}, { title: 'SubItem Item 2-2'}]},
             ],
            ts_mnf: [],
            tag :'',  
            tag_name:'',
            file_info : '',
        }
    },
    methods: {
      change_min(){
          if(this.vchart === 0){
             this.vchart =1 ;
             this.chang_bar_url();  
          }
        },
        chang_bar_url(){
             this.$refs.barchart.fixed = 1 ; // 1 :fixed
             this.$refs.barchart.min = this.min ;
             var c_url = this.bar_url1.split('#') ;
             this.bar_url = c_url[0]+"&fixed="+this.vchart+"&"+ this.min+'#1' ;
        },
        chart_exceldownload() {
          // this.$http.get(this.URL+'api/'+idx).then((response) => {
          // 1:day chart, 2:minutes chart 
       
          var surl = this.URL+'api/m2/'+ this.box[this.activeCheckIndex].psr_file_path+"?retType=bar&sensor=" ;
       // var fname =  this.psr_download_arr[this.psr_download_idx].id+"_"+this.psr_download_arr[this.psr_download_idx].psr_file_name ;
          var fname =  this.box[this.activeCheckIndex].psr_file_path+"_"+this.box[this.activeCheckIndex].measure_start_datetime ;
          
          this.$http({
            // url: this.URL+'api/download/psr/'+idx,
            ///api/API21/FILE/575c5303-b633-40c2-858b-b9e54ec865be?outType=psrToJson
            url:  surl,
            method: 'GET',
            //responseType: 'blob',
            //data: JSON.stringify(data),
            responseType: 'json',
            contentType: 'application/json; charset=utf-8',
          }).then((response) => {
            // let data = JSON.parse(decodeURIComponent(response.data))
          //  let json_data = JSON.stringify(response.data) ;
              let data = this.jsonToCSV(response.data.data)
            var fileURL = window.URL.createObjectURL(new Blob([data]));
            var fileLink = document.createElement('a');
            fileLink.href = fileURL;
            fileLink.setAttribute('download', fname+".csv");
            document.body.appendChild(fileLink);
            fileLink.click();
            setTimeout(()=>{
              ++this.psr_download_idx
            }, 200);
      
          })

        },
        jsonToCSV(json_data) { // 1-1. json 데이터 취득
          const json_array = json_data; // 1-2. json데이터를 문자열(string)로 넣은 경우, JSON 배열 객체로 만들기 위해 아래 코드 사용 
          
          let csv_string = ''; // 3. 제목 추출: json_array의 첫번째 요소(객체)에서 제목(머릿글)으로 사용할 키값을 추출
          //const titles = Object.keys(json_array[0]); // 4. CSV문자열에 제목 삽입: 각 제목은 컴마로 구분, 마지막 제목은 줄바꿈 추가 
            //titles.forEach((title, index)=>{ csv_string += (index !== titles.length-1 ? `${title},` : `${title}\r\n`); }); 
            csv_string += 'date, Pressure(bar)\r\n';
            json_array.forEach((content, index)=>{ 
              let row = '';
              // 각 인덱스에 해당하는 '내용'을 담을 행
              for(let title in content){ // for in 문은 객체의 키값만 추출하여 순회함.
              // 행에 '내용' 할당: 각 내용 앞에 컴마를 삽입하여 구분, 첫번째 내용은 앞에 컴마X
                  row += (row === '' ? `${content[title]}` : `,${content[title]}`); } 
                  // CSV 문자열에 '내용' 행 삽입: 뒤에 줄바꿈(\r\n) 추가, 마지막 행은 줄바꿈X 
                  csv_string += (index !== json_array.length-1 ? `${row}\r\n`: `${row}`); }) 
              // 6. CSV 문자열 반환: 최종 결과물(string) 
          return csv_string;
        },

        updateCheckIndex(payload) {
          this.activeCheckIndex = payload
          //this.tag=this.box[payload].id
        //  var sdate = this.box[payload].measure_start_datetime === null ? '2022-01-01':this.box[payload].measure_start_datetime
          //this.tag_name = this.box[payload].name
          this.file_info = this.box[payload].measure_start_datetime+"~"+this.box[payload].measure_end_datetime
          this.get_time_series_flow() 
       
        },
        change_date_mnf(a){
          this.isShowDate1 = true;
          let default_date = new Date(this.mnf_date.toLocaleString());
           default_date.setDate(default_date.getDate()+Number(a)).toLocaleString() ;
          this.mnf_date = this.date_to_str(default_date)      
          this.get_time_series_mnf()
        },
        change_date_flow(a){
          this.isShowDate1 = true;
          let default_date = new Date(this.flow_date.toLocaleString());
           default_date.setDate(default_date.getDate()+Number(a)).toLocaleString() ;
          this.flow_date = this.date_to_str(default_date)
         
          this.get_time_series_flow()
        },
        date_to_str(format){
          var year = format.getFullYear();
          var month = format.getMonth() + 1;
          if(month<10) month = '0' + month;
          var date = format.getDate();
          if(date<10) date = '0' + date; 
     
          return year + "-" + month + "-" + date ;
      },

      on_goto_map_click(row) {
        var last_str = row.date.substr(0,10)       
        this.$router.push({path: '/pressure-monitoring-map?w_type=111', query: { id: row.id, mea_date:last_str  }})
          
      },
       go_search(value,sort,type,page) {

          if(value===undefined) return;

          //left메뉴 데이터를 뿌려준다.
          this.box = [] ;
          this.cnt = 0;
          
          this.$http.get(this.URL+'api/m2/fileList/'+this.tag+"?search_term="+value+
          "&page="+page+"&per_page=10&sort_field="+sort+"&sort_type="+type).then((res) => {
          //this.$http.get(this.URL+'api/API21/m2_press/list?customer_id='+customer_id).then((res) => {
              if(res.data.rows.length <1) return
              this.cnt = res.data.cnt
              for(let i=0; i<res.data.rows.length; i++) {
                      
                var abox = {};
          
               abox = {'idx':i,'name': this.tag_name, 'id': res.data.rows[i].id , 'device_sn':  res.data.rows[i].end_time ,
                  'avg':res.data.rows[i].avg ,'min':res.data.rows[i].min ,'max':res.data.rows[i].max ,'psr_file_path':res.data.rows[i].file_id,            
                  'measure_end_datetime':  res.data.rows[i].end_time,'measure_start_datetime':  res.data.rows[i].start_time, } ; 
                  this.box.push(abox) ; 
              }
              this.updateCheckIndex(0)
            
            })
     
        },
        showDate() {
          this.isShowDate = !this.isShowDate
        },
          showDate1() {
          this.isShowDate1 = !this.isShowDate1
        },
        tabItem(index){
          this.activeIndex = index
          if(index === 1 && this.isInit) { 
            this.get_time_series_mnf()
            this.isInit = false
          }else if(index === 2 && this.isInit1) {
            this.isInit1 = false  
          }
        },
        change_flow_date(e) {
            this.flow_date = e
            this.get_time_series_flow()
        },
        change_selected_ts_flow(e) {
            this.selected_ts_flow = e
            this.get_time_series_flow()
        },
        get_time_series_flow() {
            this.loaded_ts_flow = false    
            //this.$refs.realchart.fixed = 0 ;
           // this.real_url = this.URL+'api/API21/m2_press/minute/'+encodeURI(this.tag)+'?to='+this.flow_date+'&limit='+this.selected_ts_flow+"#1";
            this.real_url = this.URL+'api/m2/'+ this.box[this.activeCheckIndex].psr_file_path+'?sensor=&retType='+this.cvchart 
            this.loaded_ts_flow = true
        },
        change_mnf_date(e) {
            this.mnf_date = e
         
        },
        change_selected_ts_mnf(e) {
            this.selected_ts_mnf = e
          
        },
        get_time_series_mnf() {
          this.bar_url1 = this.URL+'api/API21/m2_press/day/'+encodeURI(this.tag)+'?to='+this.mnf_date+'&limit='+this.selected_ts_mnf+"#1";
        
        },

        window_resiz() {
          this.contents_height = window.innerHeight - this.$refs.navbar.clientHeight 
         // let left_height = this.contents_height - (this.$refs.button_group_realtime.clientHeight*2)        
          
          this.mnfchart_height = this.contents_height   -50
          this.realtimechart_height = this.mnfchart_height
         // this.$refs.map.update_size();
        },
         
    },
    mounted () {
      window.addEventListener("resize", this.window_resiz);
      this.window_resiz()

      this.tag= Number(this.$route.query.tag) ;
      this.tag_name = this.$route.query.tag_name + "/" + this.$route.query.device_sn
      this.box = [] ;
        //var customer_id = sessionStorage.select_customer_id ; 
      //paging 처리 필요  
      this.$http.get(this.URL+'api/m2/fileList/'+this.tag+"?page=1&per_page=10").then((res) => {
       //this.$http.get(this.URL+'api/API21/m2_press/list?customer_id='+customer_id).then((res) => {
          if(res.data.rows.length <1) return
          this.cnt = res.data.cnt
          var sel_id = 0
          for(let i=0; i<res.data.rows.length; i++) {
            if(this.tag === res.data.rows[i].id ){
              sel_id = i
              this.file_id = "start_time : " + res.data.rows[i].start_time +" / end_time : "+ res.data.rows[i].end_time 
            }           
            var abox = {};
           
            abox = {'idx':i,'name': this.tag_name, 'id': res.data.rows[i].id , 'device_sn':  res.data.rows[i].end_time ,
                  'avg':res.data.rows[i].avg ,'min':res.data.rows[i].min ,'max':res.data.rows[i].max ,'psr_file_path':res.data.rows[i].file_id,            
                  'measure_end_datetime':  res.data.rows[i].end_time,'measure_start_datetime':  res.data.rows[i].start_time, } ; 
                  this.box.push(abox) ; 
          }
          this.updateCheckIndex(sel_id)
         
       })
       setTimeout(()=>{
         var elem = document.getElementsByClassName("page-wrapper chiller-theme toggled") 
         if( elem !== undefined ){
           if(elem.length > 0){
               document.getElementsByClassName("un-toggle-menu-btn-tri-off")[0].click();         
           }
         }
       }, 200)
    },
    beforeDestroy () {
      window.removeEventListener('resize', this.window_resiz)
    },
    components: {
        // PulseLoader: PulseLoader,
        SearchLeftType3,
      //  PressMonitoringDayChart:PressMonitoringDayChart,
      //  PressMinChart:PressMinChart,
        pressChartData1:pressChartData1,
    }
}
</script>

<style  lang="scss">
.presschart_m2_c1 .wavchart  {
  /*min-height: 650px;*/
  height : calc(70vh - 50px);
}
.presschart_m2_c1 .highcharts-container {
  width: 100%;
  margin: 0 auto;
}
.presschart_m2_c1 .highcharts-container .highcharts-root {
  width: 100%;
  margin: 0 auto;
}
.map-button.icon_btn.btn {
    padding-top: 0;
}
.d3-tip td {
  color: #fff;
  font-weight: 400;
  font-size: 13px;
}
.btn_list_wrap .btn_list .lookup_btn.search{
    margin-right: 96px;
}
.map-button.icon_btn.btn.btn-sm1{
  padding: 0;
}

.tab-chart-box.chart_box .tab_box.press_logger{
    min-height: calc(100vh - 58px - 70px - 15px);
}
</style>
